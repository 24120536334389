export default {

  data: () => ({
    SCROLL_REMEMBER_INFO_NAME: 'SCROLL_REMEMBER_INFO',
    SCROLL_REMEMBER_INFO_ARR_NAME: 'SCROLL_REMEMBER_INFO_ARR',
    DELIVER_KEY_STORE_NAME: 'DELIVER_KEY_STORE',
    
    scrollRemember: {
      fnMaxScrollCallback: undefined,
      fnScrollEventCallback: undefined,
      fnBeforeScrollLoad: undefined,
      maxScrollAlpha: 1400,
      rememberPage: 1,
      enableScrollMove: true,
      extra: undefined
    },
    currentScrollY: 0,
    loadedInfoArr: [],
    REMEMBER_PAGE_SIZE: 6,
    pageFullPath: undefined,
    pageHistoryKey: undefined,
    delayMs: 0
  }),

  watch: {
    async "$route.query"(newVal, orgVal) {
      //console.log(`watch`);
      if(typeof window != 'undefined'){
        window.sessionStorage.removeItem(this.DELIVER_KEY_STORE_NAME);
        this.saveScrollInfo();
        await this.scrollMoveAction();
      }
    }
  },

  async mounted() {
    if(typeof document != 'undefined') {
      document.addEventListener('click', this.interceptHrefMove);
    }
    await this.scrollMoveAction();
  },

  beforeDestroy() {
    if(typeof window != 'undefined'){
      this.saveScrollInfo();
    }

    if(typeof document != 'undefined') {
      document.removeEventListener("scroll", this.onMaxScrollListener);
    }

    if(typeof document != 'undefined') {
      // 전역 클릭 이벤트 리스너 추가
      document.removeEventListener('click', this.interceptHrefMove);
    }

    if(typeof document != 'undefined') {
      window.removeEventListener('beforeunload', this.beforeunload);
    }



  },

  methods: {

    sleep(ms) {
      return new Promise((r) => setTimeout(r, ms));
    },

    /**
     * 사용자가 윈도우에서 떠날떄 (새로고침때도 호출함)
     */
    beforeunload(e) {
      //window.history.scrollRestoration = 'auto';
      /*
      //this.pageHistoryKey = Number( (window.history.state||{}).key || 0 ) ;
      console.log(`org Key : ${this.pageHistoryKey}`);
      console.log(`current Key : ${(window.history.state||{}).key }`);
      //if(this.scrollRemember.enableScrollMove) {
        this.saveDeliverKey();
      //}
      this.saveScrollInfo();
      */
    },

    /**
     * 전달용 페이지key 저장
     */
    saveDeliverKey(){
      try {
        const keyObj = {
          fullPath: this.pageFullPath,
          pageHistoryKey: this.pageHistoryKey
        };
        const keyArrStr = window.sessionStorage.getItem(this.DELIVER_KEY_STORE_NAME) || '[]';
        let keyArr = JSON.parse(keyArrStr);
        keyArr.push(keyObj);
  
        keyArr = this.getSpliceArr(keyArr, 2);
  
        window.sessionStorage.setItem(this.DELIVER_KEY_STORE_NAME, JSON.stringify(keyArr));

      } catch (e) {}
    },

    /**
     * 전페이지에서 넘겨받은 key값이 있으면 가져오고 삭제 
     */
    getDeliverKey() {
      try {
        const keyArrStr = window.sessionStorage.getItem(this.DELIVER_KEY_STORE_NAME);
        const keyArr = JSON.parse(keyArrStr||[]);

        let filteredArr = keyArr.filter(keyObj => {
          return keyObj && keyObj.fullPath == this.pageFullPath;
        });

        if(filteredArr.length > 0) {
          const keyObj = filteredArr[filteredArr.length-1];
          const key = keyObj.pageHistoryKey;
          window.sessionStorage.removeItem(this.DELIVER_KEY_STORE_NAME);
          return key;
        }

      } 
      catch (e) {}
      return undefined;
    },

    /**
     * 페이지스크롤 이력 
     * REMEMBER_PAGE_SIZE count만큼 남기고 가장 오래된 이력을 자른다.
     */
    getSpliceArr (keyArr, size) {
      const currentHistoryLength = keyArr.length;
      const historySpliceIndex = (currentHistoryLength > size) ? currentHistoryLength - size : 0 ;
      return keyArr.splice(historySpliceIndex);
    },

   /**
    * 세션스토리지에 스크롤 이력데이터를 저장 (Array)
    */
    saveScrollInfo() {
      this.removeAlreadyScrollInfo();
      let pageScrollInfo = { 
        fullPath: this.pageFullPath,
        rememberPage: this.scrollRemember.rememberPage,
        scrollPosition: this.currentScrollY || 0,
        pageHistoryKey: this.pageHistoryKey,
        extra: this.scrollRemember.extra
      };
      this.loadedInfoArr.push(pageScrollInfo);
      this.spliceScrollHistory();
      window.sessionStorage.setItem(this.SCROLL_REMEMBER_INFO_ARR_NAME, JSON.stringify(this.loadedInfoArr));
    },

    /**
     * url 변경시 실행 (최초진입, 뒤로가기, 등등.) 스크롤 복원대상이면 복원함
     */
    async scrollMoveAction() {
      //console.log(`SrollMove Key : ${(window.history.state||{}).key}`);
      await this.$nextTick(() => {});
      this.pageFullPath = this.$route.fullPath;

      const deliverKey = this.getDeliverKey();
      if(deliverKey) {
        window.history.replaceState({key: deliverKey}, '');
      }

      if(typeof window != 'undefined') {
        this.pageHistoryKey = Number( (window.history.state||{}).key || 0 ) ;
      }
      
      if(typeof document != 'undefined') {
        document.removeEventListener("scroll", this.onMaxScrollListener);
        document.addEventListener("scroll", this.onMaxScrollListener, { passive: true });
      }

      if(typeof window != 'undefined') {
        window.removeEventListener('beforeunload', this.beforeunload);
        window.addEventListener('beforeunload', this.beforeunload);
      }
      
      if(this.scrollRemember.enableScrollMove && process.client) {
        this.loadScrollInfo();
        if(this.scrollRemember.fnBeforeScrollLoad) {
          await this.scrollRemember.fnBeforeScrollLoad();
        }

        //랜더링 딜레이 (임시로 해두고 나중에 좋은방법을 찾아보자.)
        await this.sleep(this.delayMs);
    
        await this.$nextTick(() => {});
        const rememberPageInfo = this.getLoadedScrollInfo();
        //this.removeHigherHistoryLengthPage();
        if(rememberPageInfo) {
          window.scrollTo(0, rememberPageInfo.scrollPosition || 0);
        }
      }

      
      if(!this.scrollRemember.enableScrollMove) {
        this.saveDeliverKey();
      }
      
    },

    /**
     * 페이지이동을 뒤로가기로 왔을경우 히스토리 length가 높은 페이지 삭제 (뒤로가기 대상만 저장하기위해)  
     */
    removeHigherHistoryLengthPage(){
      const remainPageInfoArr = this.loadedInfoArr.filter(pageScrollInfo => {
        return pageScrollInfo.pageHistoryKey < this.pageHistoryKey ;
      });

      this.loadedInfoArr.splice(0);
      this.loadedInfoArr.push(...remainPageInfoArr);
    },

    /**
     * 혹시 중복되는 url(fullPath)의 스크롤데이터가 있으면 지우고 갑니다.
     */
    removeAlreadyScrollInfo() {
      const atomicInfoArr = this.loadedInfoArr.filter(pageScrollInfo => {
        return pageScrollInfo.fullPath != this.pageFullPath;
      });

      this.loadedInfoArr.splice(0);
      this.loadedInfoArr.push(...atomicInfoArr);
    },

    /**
     * 세션스토리지에서 스크롤 이력 데이터를 불러온다.
     */
    loadScrollInfo() {
      let loadedInfoArr = window.sessionStorage.getItem(this.SCROLL_REMEMBER_INFO_ARR_NAME);
      if(loadedInfoArr) {
        this.loadedInfoArr = JSON.parse(loadedInfoArr);
      }

      let loadedScrollInfo = this.getLoadedScrollInfo();
      const rememberPage = (loadedScrollInfo||{}).rememberPage;
      if (typeof rememberPage == 'number' && rememberPage > 0) {
        this.setRememberPage(rememberPage);
      }

      const extra = (loadedScrollInfo||{}).extra
      if(typeof extra == 'object') {
        this.setExtra(extra);
      }
    },

    /**
     * 페이지스크롤 이력 
     * REMEMBER_PAGE_SIZE count만큼 남기고 가장 오래된 이력을 자른다.
     */
    spliceScrollHistory () {
      const currentHistoryLength = this.loadedInfoArr.length;
      const historySpliceIndex = (currentHistoryLength > this.REMEMBER_PAGE_SIZE) ? currentHistoryLength - this.REMEMBER_PAGE_SIZE : 0 ;
      this.loadedInfoArr = this.loadedInfoArr.splice(historySpliceIndex);
    },

    /**
     * 
     * 현재 페이지대상 페이지 스크롤이력이 있으면 반환한다.
     */
    getLoadedScrollInfo() {
      const filterdLoadedInfoArr = this.loadedInfoArr.filter(pageScrollInfo => {
        return pageScrollInfo.fullPath == this.pageFullPath && this.pageHistoryKey == pageScrollInfo.pageHistoryKey;
      });

      if(filterdLoadedInfoArr && filterdLoadedInfoArr.length >= 1) {
        return filterdLoadedInfoArr[filterdLoadedInfoArr.length-1];
      }

      return undefined;
    },

    initOnMaxScrollOptions : function(options = {}) {

      //fnMaxScrollCallback: 스크롤이 끝에 다다를때 호출할 함수
      this.scrollRemember.fnMaxScrollCallback = options.fnMaxScrollCallback;

      //fnBeforeScrollLoad: 페이지 진입시 스크롤 복구 하기전에 호출할 전처리함수
      this.scrollRemember.fnBeforeScrollLoad = options.fnBeforeScrollLoad;

      //스크롤 이벤트 발생시마다 callback함수
      this.scrollRemember.fnScrollEventCallback = options.fnScrollEventCallback;

      //maxScrollAlpha: 스크롤이 바닥인지 체크할때 스크롤y값 조정값 (default:1400)
      if(typeof options.maxScrollAlpha == 'number') {
        this.scrollRemember.maxScrollAlpha = options.maxScrollAlpha;
      }

      //enableScrollMove 스크롤 자동복구 사용여부 (default:true)
      if(typeof options.enableScrollMove ==  'boolean') {
        this.scrollRemember.enableScrollMove = options.enableScrollMove;
      }

      //스크롤 딜레이
      if(typeof options.delayMs == 'number') {
        this.delayMs = options.delayMs;
      }

    },

    /**
     * 페이지번호 정보 저장 
     */
    setRememberPage(page) {
      this.scrollRemember.rememberPage = page;
    },

    /**
     * extra 데이터 저장
     */
    setExtra(extra){
      this.scrollRemember.extra = extra;
    },

    onMaxScrollListener() {
      this.currentScrollY = document.documentElement.scrollTop;

      let cp =
        document.getElementById('__nuxt').clientHeight -
        window.innerHeight - document.documentElement.scrollTop;

      if (cp <= this.scrollRemember.maxScrollAlpha && this.scrollRemember.fnMaxScrollCallback) {
        this.scrollRemember.fnMaxScrollCallback();
      }

      if(this.scrollRemember.fnScrollEventCallback) {
        this.scrollRemember.fnScrollEventCallback(cp);
      }
    },

    /**
     * a href이동 인터셉트 (내부도메인이면 nuxt-link이동으로 보내기)
     */
    interceptHrefMove(event) {
      const target = event.target;

      //path 테스트 START
      /*
      const tempPath = target.getAttribute('href');
      console.log('target : ', target);
      console.log(tempPath);
      event.preventDefault();
      */
      //path 테스트 END

      // `<a>` 태그를 클릭했는지 확인
      if (target.tagName === 'A' && target.href) {
        // href 속성에서 경로 추출
        const path = target.getAttribute('href');
        // Vue Router를 사용하여 라우트 변경
        if(this.checkInternalDomain(path)) {
          try {
            const url = new URL(path);
            //console.log(`${url.pathname}${url.search}`);
            this.$router.push(`${url.pathname}${url.search}`);
            // 기본 동작 방지
            event.preventDefault();
          } catch (e) {}
        }
      }
    },

    /**
     * 도메인 내부이동인지 체크
     */
    checkInternalDomain(path) {
      if(typeof window != 'undefined') {
        const currentDomain = window.location.origin;
        const pathDomain = new URL(path).origin;
        // 현재 도메인과 경로의 도메인이 같거나 경로가 상대 경로인 경우 내부 도메인으로 인식
        return currentDomain === pathDomain || path.startsWith('/') || path.startsWith('./') || path.startsWith('../');
      }
    }

  },

};
