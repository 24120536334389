export default {
  computed: {
    component() {
      return this.$device.isMobile ? "mobile" : "desktop";
    },
  },

  beforeCreate() {
    // const currentRoute =
    //   this.$route.matched.find((route) => route.name === this.$route.name) || this.$route;
    // const path = currentRoute.path.replace(":", "_").replace("?", "");
    // this.$options.components.Mobile = require(`@/components/pages${path}/Mobile`).default;
    // this.$options.components.Desktop = require(`@/components/pages${path}/Desktop`).default;
  },

  async mounted() {
    const crema = window.crema
    //await this.$nextTick()
    //await this.$nextTick(() => this.$device.isMobile ? document.body.scroll(0, 0) : window.scroll(0, 0) ); 
    try {
      crema ? crema.run() : window.cremaAsyncInit()
    } catch (error) {}
    
    // let userName = null;
    // let userId = null;
    // if (this.$store.state.session.isMember) {
      // let profile = this.$store.state.session.profile;
      // userName = '"'+profile.name+'"';
      // email: profile.email,
      // userId = '"'+profile.loginId+'"';

      // this.userProfile = {
      //   userName: profile.name,
      //   email: profile.email,
      //   userId: profile.loginId,
      // };
    // }
    // try {
      // console.log("Crema Init");
      // window.cremaAsyncInit = function () {
      //   console.log("Crema Init Start");

      //   crema.init(userName, userId);
      // };
      // window.cremaAsyncInit()
    // } catch (error) {
      // console.log(`crema init error\n> ${error}`)
    // }
  }
};
